@import '../../views/common/common.scss';
.dropDownWrapper {
    > div {
        width: 10rem;
        position: relative;
    }
   #Dept,#Class,#SubClass {
    width: 18rem;
   }
   #Sku {
    width: 6rem;
   }
    .headLabel {
        display: block;
        background-color: #fff;
        font-family: HelveticaNeueMedium;
        color: #222;
        padding-left: 0.5rem;
        font-size: 0.65rem;
        position: relative;
        line-height: 1;
        padding: 0.5rem;
        padding-bottom: 0.3rem;
        border: 1px solid #222;
    &.inActive {
        opacity: 0.5;
        background: #ccc;
    }
    
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 89%;
            display: inline-block;
        }
        i {
            position: absolute;
            right: 0;
        }
    }
}
.dropDownContent {
    width: 100%;
    max-height: 15rem;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #fffffffa;
    position: absolute;
    top: 1.5rem;
    z-index: 1;
    padding: 0.5rem 0.25rem;
    border: 1px solid #222;
    > div {
        margin-bottom: 0.25rem;
    }
    p {
        color: #222;
        font-family: HelveticaNeueBold;
        font-size: 0.6rem;
        display: flex;
        align-items: center;
    }
    input {
        border: 1px solid #222;
        margin-right: 0.25rem;
        cursor: pointer;
    }
    label {
        cursor: pointer;
    }
}
.dropDownContainer {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    align-items: flex-end;
    position: sticky;
    top: 2.45rem;
    background: #f2f2f2;
    z-index: 10;
    padding: 0.5rem 0;
    h4 {
        font-size: 1rem;
        margin-bottom: 0.25rem;
        font-family: HelveticaNeueBold;
    }
}
.dropdownResetBtn {
    width: 5rem;
    appearance: none;
    border: 0;
    background: $red-bg-color;
    color: #fff;
    font-size: 0.85rem;
    // text-transform: uppercase;
    font-family: HelveticaNeueBold;
    padding: 0.4rem 0.5rem;
    line-height: 1;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
        background-color: #2542b6;
    }
}
.skuTypeSelect {
    position: relative;
    width: 6rem;
    i {
        position: absolute;
        top: 0.15rem;
        right: 0;
        font-size: 0.65rem;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
             appearance: none;
             width: 100%;
             line-height: 1;
             font-size: 1rem;
             padding: 0.45rem;
             font-size: 0.65rem;
             outline: none;
             border: 1px solid #222;
             &:disabled {
                opacity: 0.5;
                background: #ccc;
             }
    }
}
.skuSearch {
    input {
        width: 100%;
        line-height: 1.5;
    }
    position: relative;
    i {
        position: absolute;
        right: 2px;
    }
}
.silverUserWrapper {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
    height: 86vh;
    min-height: 700px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    img {
        max-width: 100%;
    }
    p {
        position: absolute;
        justify-content: center;
        align-items: center;
        z-index: 11;
        color: #dcdcdc;
        padding: 3rem;
        font-family: HelveticaNeueBold;
        cursor: no-drop;
        cursor: not-allowed;
        font-size: 1.25rem; 
    }
    &::after {
        content: '';
        position: absolute;
        background-color: rgba(0,0,0,0.8);
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
    }
}