@import "../../views/common/common.scss";
.deleteAllBtn {
  width: 8rem;
  appearance: none;
  border: 0;
  background: #d3d3d3;
  font-size: 0.85rem;
  font-family: HelveticaNeueBold;
  padding: 0.4rem 0.5rem;
  line-height: 1;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.25s;
}
.m-lt {
  margin-left: 0.5rem;
}
.m1-lt {
  margin-left: 1rem;
}
.m5-rt {
  margin-right: 0.5rem;
}

.p1-lt {
  padding-left: 1rem;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  justify-items: center;
}
.lableText {
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  width: 9rem;
  margin-right: 1rem;
  
}
.inputBox {
  margin-left: 1rem;
  font-family: HelveticaNeueMedium; 
  padding: 5px;
  font-size: 12px;
  width: 18rem;
  height: 1.6rem;
  border: black solid 1px;
}
.checkBoxLable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#Dept,
#Class,
#SubClass {
  width: 18rem;
}
.addToSKU {
  float: right;
  width: auto;
  appearance: none;
  border: 0;
  background: $red-bg-color;
  color: #fff;
  font-size: 0.85rem;
  // text-transform: uppercase;
  font-family: HelveticaNeueBold;
  padding: 0.4rem 0.5rem;
  line-height: 1;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    background-color: #2542b6;
  }
}
.formContainer{
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
  // position: absolute;
  // top: 40%;
  // left: 40%;
  // margin-top: -50px;
  // margin-left: -50px;
  // min-width: 40%;
  width: 50%;
 
}
.checkBoxContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 18rem,
}
.deleteSkuMsg {
  padding: 1rem;
  font-family: HelveticaNeueBold;
}
.btnSubmit{
   margin-top: 1rem;
   align-self: flex-end;
}
.PM-container {
  display: flex;
  flex-direction: row;
  z-index: 2;
  margin-top: 2rem;
}
.btn-position {
  position: absolute;
  left: 56rem;
}
.ft-right {
  float: right;
}
.warn-text {
  font-style: italic;
  font-weight: bold;
  color: red;
  font-size: 12px;
  vertical-align: middle; 
  margin-bottom: 5px !important;
  width: 20rem;
}
.errorInput{
  border: 1px solid red;
}

