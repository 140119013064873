@charset "UTF-8";
@font-face {
  font-family: HelveticaNeue;
  src: url('../src/fonts/HelveticaNeue Light.woff2');
  src: url("../src/fonts/HelveticaNeueCyr-Light.eot"),
  url("../src/fonts/HelveticaNeueCyr-Light.woff") format("woff"); 
}
@font-face {
  font-family: HelveticaNeueMedium;
  src: url('../src/fonts/HelveticaNeue Medium.woff2'),
  url("../src/fonts/Helvetica Neu Medium.woff") format("woff");
}
@font-face {
  font-family: HelveticaNeueBold;
  src: url('../src/fonts/Helvetica Neue Bold.eot'),
  url("../src/fonts/Helvetica Neu Bold.woff") format("woff");
}
@font-face {
  font-family: Franchise;
  src: url('../src/fonts/Franchise-Bold.woff2');
  src: url("../src/fonts/Franchise-Bold.eot"),
  url("../src/fonts/Franchise-Bold.woff") format("woff");
}
@font-face {
  font-family: Antonio-Bold;
  src: url('../src/fonts/Antonio-Bold.ttf'),
}
@font-face {
  font-family: Antonio-Medium;
  src: url('../src/fonts/Antonio-Medium.ttf'),
}
@font-face {
  font-family: Antonio-Regular;
  src: url('../src/fonts/Antonio-Regular.ttf'),
}
@font-face {
  font-family: Antonio-SemiBold;
  src: url('../src/fonts/Antonio-SemiBold.ttf'),
}
body {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue;
  background-color: '#f2f2f2';
}
#root {
  min-width: 1000px;
}
.contentContainer {
  min-height: 97vh;
  background: #f2f2f2;
}
.gridContainer {
  width: 90%;
  margin: auto;
  padding: 0.5rem;
}
.gridContainer > section:nth-of-type(1) {
  min-height: 20rem;
}
.footerWrapper {
  background-color: #000000;
  min-height: 3vh;
  display: flex;
  justify-content: center;
  align-items:center;
}
.footerWrapper p {
  color: #fff;
  text-align: center;
}
.displayBlock{
  display: block !important;
}
.displayNone{
  display: none !important;
}
.displayFlex {
  display: flex;
}
.displayInlineFlex {
  display: inline-flex;
}
.impulseMobileDetail {
  display: block;
}
.displayInline {
  display: inline;
}
.disabled{
  cursor: not-allowed;
  color: #919191;
  text-decoration: none;
}

.styled-cb{
  -webkit-appearance: none;
}
.styled-cb + label{  
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  font-weight: bold;
}
.styled-cb:checked + label:after{
  content: '✔';
}
.styled-cb:not(:checked) + label:after{
  content:'';
}
.commonLoader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top:0;
  bottom: 0;
  left:0;
  right: 0;
  z-index: 1000;
}
.commonLoader .ui.loader::before {
  border: 0.2em solid rgb(56 57 58);
}
.commonLoader .ui.loader::after  {
  border-color: #fff transparent transparent;
}
@media (min-width:1220px) {
  .gridContainer {
    width: 1180px;
  }
}